<template>
  <div >
    <p v-for="(item, index) in overview" :key="index">{{ item }}</p>
  </div>
</template>

<script>
export default {
  props: {
    overview: {
      type: Array,
      default: () => [],
    },
  },
};
</script>