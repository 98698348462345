<template>
  <div class="slide-to-left introduction-all-payment">
    <!-- deskripsi -->
    <p class="caredokter-name-payment mb-4" v-if="isRunMobile">
      {{ detailMethodPayment.paymentMethod.name }}
    </p>
    <div class>
      <!-- instruction -->
      <div>
        <div class="card-container border-rounded">
          <div style="margin-bottom: 24px">
            <!-- card-instruction -->
            <template
              v-if="
                detailTransaksi.paymentTransactionStatus == 'CREATED' ||
                isChangeMethodPayment
              "
            >
              <over-view-after
                :overview="
                  detailMethodPayment.paymentMethod.instruction.overview
                "
              ></over-view-after>
            </template>
            <div class="grid-list-bank mb-4 bg-biru-hopital-e py-2">
              <img
                class="m-auto"
                :src="setImage(detailMethodPayment.paymentMethod.icon)"
                @error="$event.target.src = defaultImage"
              />
              <div class="list-content-bank">
                <div class="list-name-bank text-font-1">
                  {{ detailMethodPayment.paymentMethod.name }}
                </div>
                <div class="list-caption-bank text-font-2">
                  {{ detailMethodPayment.paymentMethod.description }}
                </div>
              </div>
            </div>
            <!--  -->
            
            <!-- apabila mempunyai deeplink -->
            <template
              v-if="
                detailTransaksi.paymentTransactionStatus == 'UNDERPAYMENT' &&
                detailTransaksi.payment &&
                detailTransaksi.payment.deepLink &&
                isRunMobile &&
                !isChangeMethodPayment &&
                !isLoadingCharge
              "
            >
              <div class="notice primary">
                <div class="content">
                  <deeplink-mobile
                    :time="detailTransaksi.payment.timeExpired"
                    :deep-link="detailTransaksi.payment.deepLink"
                  ></deeplink-mobile>
                </div>
              </div>
            </template>
            <template
              v-else-if="
                detailTransaksi.paymentTransactionStatus == 'UNDERPAYMENT' && !isChangeMethodPayment
              "
            >
              <div class="main-content" v-if="!isLoadingCharge">
                <introduction-after-payment 
                :after-instruction="detailMethodPayment.paymentMethod.afterInstruction"
                :payment="detailTransaksi.payment"
                :gross-amount="detailTransaksi.grossAmount"
                />
              </div>
            </template>
          </div>
          <div>
            <img src="@/assets/img/help.svg" alt="">
            <span class="ml-2 introduction-pay-label">{{staticText.caraMembayar}}</span>
            
          </div>
          <div v-if="detailMethodPayment.paymentMethod.instruction" class="mt-2">
            <div id="accordion" v-if="detailMethodPayment.paymentMethod
                  .instruction.detail.length > 1">
              <div
                class="cards"
                v-for="(item, index) in detailMethodPayment.paymentMethod
                  .instruction.detail"
                :key="index"
              >
                <div class="heading-instruction" :id="`heading-${index}`">
                  <a
                    class="btn btn-link color-care-dokter"
                    data-toggle="collapse"
                    :data-target="`#collapse-${index}`"
                    aria-expanded="false"
                    :aria-controls="`collapse-${index}`"
                  >
                    {{ item.title }}
                  </a>
                </div>

                <div
                  :id="`collapse-${index}`"
                  class="collapse"
                  :aria-labelledby="`heading-${index}`"
                  data-parent="#accordion"
                >
                  <div class="card-body">
                    <div
                      v-for="(item, index2) in detailMethodPayment.paymentMethod
                        .instruction.detail"
                      :key="index2"
                      class="tab-pane fade"
                      :class="index2 == 0 ? ' show active' : ''"
                      :id="`bank-${index2}`"
                      role="tabpanel"
                      :aria-labelledby="`nav-${index2}`"
                    >
                      <div v-html="item.instruction"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
                <div v-html="detailMethodPayment.paymentMethod.instruction.detail[0].instruction"></div>
               
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OverViewAfter from "../Introduction/OverviewAfter.vue";
import DeeplinkMobile from "../Introduction/DeeplinkMobile.vue";
import noImage from "@/assets/default-bank.svg";
import moment from "moment";
import IntroductionAfterPayment from './IntroductionAfterPayment.vue'
import StaticText from "@/helpers/StaticText";

export default {
  data() {
    return {
      defaultImage: noImage,
      introduction: false,
      showData: {},
      staticText: new StaticText()
    };
  },
  computed: {
    ...mapState({
      detailTransaksi: (state) => state.detailTransaksi,
      listPayment: (state) => state.listPayment,
      isChangeMethodPayment: (state) => state.isChangeMethodPayment,
      detailMethodPayment: (state) => state.detailMethodPayment,
      isRunMobile: (state) => state.isRunMobile,
      isLoadingCharge: state => state.isLoadingCharge
    }),
  },
  components: {
    OverViewAfter,
    DeeplinkMobile,
    IntroductionAfterPayment
  },
  methods: {
    ...mapActions({
      chargePayment: "CHARGE_ORDER_PAYMENT",
      cancelPayment: "CANCEL_ORDER_PAYMENT",
      getDispatch: "CHECK_HAS_CHILD",
    }),
    ...mapMutations({
      setChangeMethodPayment: "SET_CHANGE_METHOD_PAYMENT",
      historyToNull: "SET_HISTORY_TO_NULL",
    }),
    changeMethod() {
      // changeMethod Payment
      this.setChangeMethodPayment(true);
      this.getDispatch("");
    },
    setImage(img) {
      return "data:image/svg+xml;base64," + img;
    },
    changeData(item) {
      // image qr code --> ${img}
      if (this.detailTransaksi.payment.qrCode) {
        item = item.replaceAll(
          "${img}",
          `${this.detailTransaksi.payment.qrCode}`
        );
      }
      // time Expired --> ${time}
      if (this.detailTransaksi.payment.timeExpired) {
        const time = moment(this.detailTransaksi.payment.timeExpired).format(
          "dddd, DD MMMM YYYY , HH : mm"
        );
        item = item.replace("${time}", `${time}`);
      }
      // va number --> ${va_number}
      if (this.detailTransaksi.payment.vaNumbers) {
        item = item.replace(
          "${va_number}",
          `${this.detailTransaksi.payment.vaNumbers}`
        );
      }
      // key_code --> ${biller_key} && ${biller_code}
      if (this.detailTransaksi.payment.billKey) {
        item = item.replace(
          "${biller_key}",
          `${this.detailTransaksi.payment.billKey}`
        );
        item = item.replace(
          "${biller_code}",
          `${this.detailTransaksi.payment.billerCode}`
        );
      }

      if (this.detailTransaksi.grossAmount) {
        item = item.replace(
          "${total}",
          `${this.detailTransaksi.grossAmount}`
        );
      }

      return item;
    },
  },
};
</script>

<style>

</style>
