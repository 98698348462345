<template>
  <div class="caredokter-list-payment">
    <label>{{staticText.pilihMetode}}</label>
    <hr class="hr-payment" />
    <div v-for="(list, index) in methodPayment" :key="index">
      <show-list-payment :list="list" />
    </div>
    <!-- Skleton loading -->
    <template v-if="!detailTransaksi.id || methodPayment.length == 0">
      <div v-for="i in 3" :key="i" class="skleton-mobile-show">
        <div class="row mt-3 mb-1">
          <div class="col-10 caredokter-payment-name">
            <rectangle-skleton
              class="m-0"
              :ref-skleton="'name'"
              :x-width="'120px'"
              :x-height="'17px'"
            />
          </div>
          <div class="col-2">
            <img src="@/assets/img/chevron-right.png" />
          </div>
        </div>
        <div class="loading">
          <div v-for="index in 3" :key="index" class="float-left mr-2">
            <circle-skleton
              :ref-skleton="`image-${index}`"
              :x-diameter="20"
              class="m-auto"
            ></circle-skleton>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import ShowListPayment from "./ShowListPayment.vue";
import CircleSkleton from "../skleton/CircleSkeleton.vue";
import RectangleSkleton from "../skleton/RectangleSkleton.vue";
import StaticText from "@/helpers/StaticText";

export default {
  data() {
    return {
      staticText: new StaticText()
    }
  },
  components: {
    ShowListPayment,
    CircleSkleton,
    RectangleSkleton,
  },
  computed: {
    ...mapState({
      methodPayment: (state) => state.listPaymentMobile,
      detailTransaksi: (state) => state.detailTransaksi,
    }),
  },
};
</script>
