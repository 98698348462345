<template>
  <div class="container-list">
    <div
      v-for="(item, index) in showListData"
      :key="index"
      class="grid-list-bank mb-3"
      @click="showRouterData(item.paymentMethod)"
    >
      <img
        class="m-auto"
        :src="setImage(item.paymentMethod.icon)"
        @error="$event.target.src = defaultImage"
      />
      <div class="list-content-bank">
        <div class="list-name-bank text-font-1">
          {{ item.paymentMethod.name }}
        </div>
        <div class="list-caption-bank text-font-2">
          {{ item.paymentMethod.description }}
        </div>
      </div>
      <img src="@/assets/img/chevron-right.png" class="m-auto" />
    </div>
    <template v-if="!detailTransaksi.id || showListData.length == 0">
      <div class="grid-list-bank my-3" v-for="i in 3" :key="i">
        <circle-skleton
          :ref-skleton="`image`"
          :x-diameter="20"
          class="m-auto"
        ></circle-skleton>
        <div class="list-content-bank">
          <div class="list-name-bank">
            <rectangle-skleton
              class="m-0"
              :ref-skleton="'name'"
              :x-width="'140px'"
              :x-height="'18px'"
            />
          </div>
          <div class="list-caption-bank">
            <rectangle-skleton
              class="m-0"
              :ref-skleton="'description'"
              :x-width="'120px'"
              :x-height="'16px'"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import noImage from "@/assets/default-bank.svg";
import { mapState, mapActions, mapMutations } from "vuex";
import RectangleSkleton from "../skleton/RectangleSkleton.vue";
import CircleSkleton from "../skleton/CircleSkeleton.vue";
export default {
  components: {
    RectangleSkleton,
    CircleSkleton,
  },
  computed: {
    ...mapState({
      showListData: (state) => state.showIntheList,
      detailTransaksi: (state) => state.detailTransaksi,
    }),
  },
  data() {
    return {
      defaultImage: noImage,
    };
  },
  methods: {
    ...mapActions({
      checkHasChild: "CHECK_HAS_CHILD",
    }),
    ...mapMutations({
          setHistory: 'SET_HISTORY_SNAP'
        }),
    setImage(img) {
      return "data:image/svg+xml;base64," + img;
    },
    showRouterData(item) {
      this.checkHasChild(item.id);
         this.setHistory(item.id)
    },
  },
};
</script>

