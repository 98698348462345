<template>
   <div>
       <div class="row mt-3 mb-1" >
           <div class="col-10 caredokter-payment-name">
               {{list.paymentMethod.name}}
           </div>
           <div class="col-2">
               <img src="@/assets/img/chevron-right.png" alt="" @click="setDataChild(list)">
           </div>
       </div>
       <div class="d-flex">
           <template v-if="list.children && list.children.length > 0">
                 <div v-for="img, index in list.children" :key="index" class="mr-2 mb-1">
                     <template v-if="index < 5 || (index == 4 && list.children > 5)">
                         <img class="bank-list-shadow" :src="setImage(img.paymentMethod.icon)" @error="$event.target.src=defaultImage" :title="img.paymentMethod.name" @click="setDataChild(img)">
                     </template>
                     <template v-else-if="index == 5">
                         <div class="border-number bank-list-shadow" @click="setDataChild(list)">
                            <span>+{{list.children.length - 5}}</span>
                         </div>
                     </template>
                 </div>
           </template>
           <template v-else>
               <img class="bank-list-shadow" :src="setImage(list.paymentMethod.icon)" @error="$event.target.src=defaultImage" @click="setDataChild(list)">
           </template>
       </div>
   </div>
</template>

<script>
import noImage from '@/assets/default-bank.svg'
import { mapActions, mapMutations } from 'vuex'

export default {
    props: {
        list: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            defaultImage: noImage
        }
    },
    methods: {
        ...mapActions({
            storeToChild : 'STORE_TO_CHILDREN',
            checkHasChild : 'CHECK_HAS_CHILD'
        }),
        ...mapMutations({
            goToChild: 'SET_IS_CHILDREN',
            setHistory: 'SET_HISTORY_SNAP'
        }),
         setImage(img){
            return "data:image/svg+xml;base64," + img
        },
        setDataChild(item){
            // console.log(item)
            // if(item.children){
            //     // this.storeToChild({name: item.paymentMethod.name, children: item.children})
            //     this.goToChild(true)
            // } else {
            //      this.checkHasChild(item.paymentMethod.id)
            // }
            this.checkHasChild(item.paymentMethod.id)
            this.setHistory(item.paymentMethod.id)
        }
    }
}
</script>

<style>

</style>