var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"slide-to-left introduction-all-payment"},[(_vm.isRunMobile)?_c('p',{staticClass:"caredokter-name-payment mb-4"},[_vm._v(" "+_vm._s(_vm.detailMethodPayment.paymentMethod.name)+" ")]):_vm._e(),_c('div',{},[_c('div',[_c('div',{staticClass:"card-container border-rounded"},[_c('div',{staticStyle:{"margin-bottom":"24px"}},[(
              _vm.detailTransaksi.paymentTransactionStatus == 'CREATED' ||
              _vm.isChangeMethodPayment
            )?[_c('over-view-after',{attrs:{"overview":_vm.detailMethodPayment.paymentMethod.instruction.overview}})]:_vm._e(),_c('div',{staticClass:"grid-list-bank mb-4 bg-biru-hopital-e py-2"},[_c('img',{staticClass:"m-auto",attrs:{"src":_vm.setImage(_vm.detailMethodPayment.paymentMethod.icon)},on:{"error":function($event){$event.target.src = _vm.defaultImage}}}),_c('div',{staticClass:"list-content-bank"},[_c('div',{staticClass:"list-name-bank text-font-1"},[_vm._v(" "+_vm._s(_vm.detailMethodPayment.paymentMethod.name)+" ")]),_c('div',{staticClass:"list-caption-bank text-font-2"},[_vm._v(" "+_vm._s(_vm.detailMethodPayment.paymentMethod.description)+" ")])])]),(
              _vm.detailTransaksi.paymentTransactionStatus == 'UNDERPAYMENT' &&
              _vm.detailTransaksi.payment &&
              _vm.detailTransaksi.payment.deepLink &&
              _vm.isRunMobile &&
              !_vm.isChangeMethodPayment &&
              !_vm.isLoadingCharge
            )?[_c('div',{staticClass:"notice primary"},[_c('div',{staticClass:"content"},[_c('deeplink-mobile',{attrs:{"time":_vm.detailTransaksi.payment.timeExpired,"deep-link":_vm.detailTransaksi.payment.deepLink}})],1)])]:(
              _vm.detailTransaksi.paymentTransactionStatus == 'UNDERPAYMENT' && !_vm.isChangeMethodPayment
            )?[(!_vm.isLoadingCharge)?_c('div',{staticClass:"main-content"},[_c('introduction-after-payment',{attrs:{"after-instruction":_vm.detailMethodPayment.paymentMethod.afterInstruction,"payment":_vm.detailTransaksi.payment,"gross-amount":_vm.detailTransaksi.grossAmount}})],1):_vm._e()]:_vm._e()],2),_c('div',[_c('img',{attrs:{"src":require("@/assets/img/help.svg"),"alt":""}}),_c('span',{staticClass:"ml-2 introduction-pay-label"},[_vm._v(_vm._s(_vm.staticText.caraMembayar))])]),(_vm.detailMethodPayment.paymentMethod.instruction)?_c('div',{staticClass:"mt-2"},[(_vm.detailMethodPayment.paymentMethod
                .instruction.detail.length > 1)?_c('div',{attrs:{"id":"accordion"}},_vm._l((_vm.detailMethodPayment.paymentMethod
                .instruction.detail),function(item,index){return _c('div',{key:index,staticClass:"cards"},[_c('div',{staticClass:"heading-instruction",attrs:{"id":`heading-${index}`}},[_c('a',{staticClass:"btn btn-link color-care-dokter",attrs:{"data-toggle":"collapse","data-target":`#collapse-${index}`,"aria-expanded":"false","aria-controls":`collapse-${index}`}},[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('div',{staticClass:"collapse",attrs:{"id":`collapse-${index}`,"aria-labelledby":`heading-${index}`,"data-parent":"#accordion"}},[_c('div',{staticClass:"card-body"},_vm._l((_vm.detailMethodPayment.paymentMethod
                      .instruction.detail),function(item,index2){return _c('div',{key:index2,staticClass:"tab-pane fade",class:index2 == 0 ? ' show active' : '',attrs:{"id":`bank-${index2}`,"role":"tabpanel","aria-labelledby":`nav-${index2}`}},[_c('div',{domProps:{"innerHTML":_vm._s(item.instruction)}})])}),0)])])}),0):_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.detailMethodPayment.paymentMethod.instruction.detail[0].instruction)}})])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }