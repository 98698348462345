<template>
  <div class="main-container" >
     <!-- mode mobile -->
    <template v-if="isRunMobile && !showIntroduction">
      <div class="header-title text-center"  v-if="!$store.state.isChildren">
          {{detailTransaksi.snapName}}
        </div>
        <customer-detail  v-if="!$store.state.isChildren" :transaction-order="detailTransaksi.transactionOrderId "
              :detail="detailTransaksi.customerDetail" 
              :is-loading="detailTransaksi.id ? false: true"
              :status="detailTransaksi.paymentTransactionStatus" />
        <method-payment  v-if="!$store.state.isChildren"/>
        <list-bank-children v-else></list-bank-children>
    </template>
    <template v-else-if="isRunMobile && showIntroduction">
      <div>
        <img src="@/assets/img/chevron-left.png" class="ml-2 mt-1" @click.prevent="setToBeforeRoute" v-if="historySNAP.length > 0">
        <introduction />
      </div>
    </template>
    <!-- mode dekstop -->
    <template v-if="!isRunMobile && !showIntroduction ">
        <stack-payment />
    </template>
    <template v-else-if="!isRunMobile && showIntroduction">
        <introduction />
    </template>
    <!-- <footer-component /> -->
  </div>
</template>

<script>
import StackPayment from "./StackPayment.vue";
import introduction from './Introduction.vue'
import CustomerDetail from '../leftComponent/CustomerDetail.vue'
import ListBankChildren from '../mobile/ListBankChildren.vue'
import MethodPayment from '../mobile/MethodPayment.vue';
// import FooterComponent from "./FooterComponent.vue";

import { mapActions, mapState } from "vuex";
export default {
  components: {
    StackPayment,
    introduction,
    CustomerDetail,
    MethodPayment,
     ListBankChildren
    // FooterComponent
  },
  data() {
    return {
      introductions: false,
      isMobile: true
    };
  },
  computed: {
    ...mapState({
      showIntroduction: (state) => state.showIntroduction,
       detailTransaksi: (state) => state.detailTransaksi,
       isRunMobile: state => state.isRunMobile,
       historySNAP: (state) => state.historySNAP
    }),
  },
  methods:{
    ...mapActions({
       setToBeforeRoute: 'SET_TO_BEFORE_ROUTE'
    })
  }
};
</script>