<template>
  <div :ref="RefSkleton">
  </div>
</template>

<script>
export default {
     props: {
        RefSkleton: {
            type: String,
            default: 'skleton'
        },
        XDiameter: {
            type: Number,
            default: 50
        }
    },
    mounted() {
        this.$refs[this.RefSkleton].style.width = this.XDiameter * 2 + 'px'
        this.$refs[this.RefSkleton].style.height = this.XDiameter * 2 + 'px'
        let gradient = `radial-gradient( circle ${this.XDiameter}px at ${this.XDiameter}px ${this.XDiameter}px, lightgray 99%, transparent 0 )`
        let backGroundImage = `linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
                ${gradient}`
        this.$refs[this.RefSkleton].style.backgroundImage = `${backGroundImage}`
        let sizebg = `50px 120px, ${this.XDiameter * 2}px ${this.XDiameter * 2}px`
        this.$refs[this.RefSkleton].style.backgroundSize = `${sizebg}`
        this.$refs[this.RefSkleton].style.backgroundRepeat = 'repeat-y'
        this.$refs[this.RefSkleton].style.backgroundPosition =  `0 0, 120px 0,`
        this.$refs[this.RefSkleton].style.animation = `shine 1s infinite`
    }
}
</script>
<style>
@keyframes shine {
		to {
			background-position:
                100% 0
		}
	}
    .demo:empty {
    margin: auto;
		width: 500px;
		height: 400px; /* change height to see repeat-y behavior */
    
		background-image:
			radial-gradient( circle 50px at 50px 50px, lightgray 99%, transparent 0 ),
			linear-gradient( 100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 ),
			linear-gradient( lightgray 20px, transparent 0 );

		background-repeat: repeat-y;

		background-size:
			100px 200px, /* circle */
			50px 200px, /* highlight */
			150px 200px,
			350px 200px,
			300px 200px,
			250px 200px;

		background-position:
			0 0, /* circle */
			0 0, /* highlight */
			120px 0,
			120px 40px,
			120px 80px,
			120px 120px;

		animation: shine 1s infinite;
	}
</style>