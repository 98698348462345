<template>
  <div class="caredokter-mobile-child-bank">
      <!-- <img src="@/assets/img/chevron-left.png" @click="goToChild(false)" > -->
      <img src="@/assets/img/chevron-left.png" @click.prevent="setToBeforeRoute" >
       <p>{{parentName}}</p>
       <div class="container-list">
        <div  v-for="item in dataChild" :key="item.id" class="grid-list-bank my-3" @click="goToIntroduction(item)">
            <img class="m-auto" :src="setImage(item.paymentMethod.icon)" @error="$event.target.src=defaultImage">
            <div class="list-content-bank">
              <div class="list-name-bank">
               {{item.paymentMethod.name}}
              </div>
              <div class="list-caption-bank">
                {{item.paymentMethod.description}}
              </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import noImage from '@/assets/default-bank.svg'
export default {
    computed: {
        ...mapState({
            parentName: (state) => state.parentName,
            dataChild: (state) => state.showIntheList
        })
    },
    data() {
        return {
            defaultImage: noImage
        }
    },
    methods: {
        ...mapMutations({
            goToChild: 'SET_IS_CHILDREN',
            setHistory: 'SET_HISTORY_SNAP'
        }),
        ...mapActions({
            checkHasChild : 'CHECK_HAS_CHILD',
            setToBeforeRoute: 'SET_TO_BEFORE_ROUTE'
        }),
         setImage(img){
            return "data:image/svg+xml;base64," + img
        },
        goToIntroduction(item) {
            this.checkHasChild(item.paymentMethod.id)
            this.setHistory(item.paymentMethod.id)
        }
    }
}
</script>

<style>
    .caredokter-mobile-child-bank{
        padding: 18px 14px 0 24px;
    }

    .caredokter-mobile-child-bank > p{
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-top: 16px;
        margin-bottom: 3em;
    }
</style>