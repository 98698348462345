<template>
  <div
    class="introduction-all-payments"
    v-html="changeData(afterInstruction)"
  ></div>
</template>

<script>
import moment from "moment";
export default {
  mounted() {
    this.checkDocument();
  },
  data(){
      return {
          timeOutCopy: ''
      }
  },
  props: {
    afterInstruction: {
      type: String,
      default: "",
    },
    payment: {
      type: [String, Object],
      default: () => {},
    },
    grossAmount: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    changeData(item) {
      // image qr code --> ${img}
      if (this.payment.qrCode) {
        item = item.replaceAll("${img}", `${this.payment.qrCode}`);
      }
      // time Expired --> ${time}
      if (this.payment.timeExpired) {
        const time = moment(this.payment.timeExpired).format(
          "dddd, DD MMMM YYYY , HH : mm"
        );
        item = item.replace("${time}", `${time}`);
      }
      // va number --> ${va_number}
      if (this.payment.vaNumbers) {
        item = item.replaceAll("${va_number}", `${this.payment.vaNumbers}`);
      }
      // key_code --> ${biller_key} && ${biller_code}
      if (this.payment.billKey) {
        item = item.replaceAll("${biller_key}", `${this.payment.billKey}`);
        item = item.replaceAll("${biller_code}", `${this.payment.billerCode}`);
      }

      if (this.grossAmount) {
         // eslint-disable-next-line no-undef
        const amount = helpers.changeCurrency(this.grossAmount)
        item = item.replaceAll("${total-copy}", `${this.grossAmount}`);
        item = item.replaceAll("${total}", `Rp ${amount}`);
      }

      return item;
    },
    checkDocument() {
      // const that = this;
      const BtnCopy = document.getElementsByClassName("btn-copy");
      if (BtnCopy.length > 0) {
        for (let i = 0; i < BtnCopy.length; i++) {
          BtnCopy[i].addEventListener("click", function (evt) {
            // event copy
            // eslint-disable-next-line no-undef
            helpers.copyValue(evt.target.getAttribute("attr-value"), evt);
            // that.createElementDestroy(evt)
          });
        }
      }
      const countdownTimer = document.getElementById("count-timer");
      if (countdownTimer) {
        // console.log("count");
        this.countTimer();
      }
    },
    
  
    
    countTimer() {
      let nowTime = moment().format("X");
      if (this.payment.timeExpired) {
        let expiredTime = moment(this.payment.timeExpired).format("X");
        let diffTime = expiredTime - nowTime;
        
        let intervalCountDown = setInterval(() => {
          nowTime++;
          if (diffTime <= 0) {
            clearInterval(intervalCountDown);
          }
          diffTime = expiredTime - nowTime;
          if( document.getElementById("count-timer")) {
            document.getElementById("count-timer").innerHTML =
              // eslint-disable-next-line no-undef
              helpers.setTimer(diffTime);
          } else {
            clearInterval(intervalCountDown)
          }
        }, 1000);
      } else {
        document.getElementById("count-timer").innerHTML = "00 : 00";
      }
    },
    
  },
};
</script>

<style></style>
