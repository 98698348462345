<template>
  <div class="text-center">
    <h5 class="title-deeplink">Tap untuk melanjutkan pembayaran </h5>
    <button class="btn caredokter-button mb-4" @click.prevent="goToUrl()">
      Buka Aplikasi
    </button>
    <div class="row bg-caredokter-time mt-0 pb-2">
      <div class="col-12">
        Harap selesaikan pembayaran sebelum: <br><strong>{{expiredTime}}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    time: {
      type: String,
      default: "",
    },
    deepLink: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.expiredTime = moment(this.time).format("dddd, DD MMMM YYYY , HH : mm");
  },
  data() {
    return {
      expiredTime: "",
    };
  },
  watch: {
    time(val) {
      this.expiredTime = moment(val).format("dddd, DD MMMM YYYY , HH : mm");
    },
  },
  methods: {
    goToUrl() {
      window.open(this.deepLink);
    },
  },
};
</script>

<style></style>
